import React, { useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
  } from "react-router-dom";
import Footer from '../footer/Footer';
import { Navbar, Nav, NavDropdown, Button } from 'react-bootstrap';
import { headerItems } from '../../business/HeaderItems';
import MasterApplication from '../schoolApplication/Master';
// import UndergraduateApplication from '../schoolApplication/Undergraduate';
import JobHunting from '../jobEdu/jobHunting/JobHunting';
import MockInterview from '../jobEdu/mockInterview/MockInterview';
import ResumeWorkshop from '../jobEdu/resumeWorkshop/ResumeWorkshop';
import AboutUs from '../aboutUs/aboutUs/AboutUs';
import JoinUs from '../aboutUs/joinUs/JoinUs';
import ContactDialog from '../utils/ContactDialog';
import Home from '../home/Home';
import PolicyTemplate from '../policies/Template';
import ScrollToTop from './ScrollToTop';
import { policies } from '../../business/Policy';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { company } from '../../business/CompanyInfo';
import './HeaderStyle.css';

const useStyles = makeStyles(theme => ({
  logo: {
    height: '45px',
    width: 'auto'
  },
  headerItem: {
    fontSize: '16px',
    fontWeight: 800,
    color: 'white',
    '&:hover': {
      color: 'orange'
    }
  },
}));

function Header() {
  const [contactOpen, setContactOpen] = useState(false);
  const [headerItemOpen, setHeaderItemOpen] = useState({});

  const styles = useStyles();

  const contactToggle = () => {
    setContactOpen(!contactOpen);
  }

  const headerItemToggle = (itemName) => {
    const itemCopy = {...headerItemOpen}
    if (itemName in itemCopy) {
      itemCopy[itemName] = !headerItemOpen[itemName]
    } else {
      itemCopy[itemName] = true;
    }
    setHeaderItemOpen(itemCopy);
  }

  return (
          <>
            <Router>
                <Navbar className={styles.header} bg="dark" variant="dark" expand="md" sticky="top">
                  <div className="container-fluid">
                    <Navbar.Brand className="ml-md-4 order-md-0 mx-auto order-1" href="/">
                      <img className={styles.logo} alt="Logo" src={company.logo} />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse className="mr-md-4 order-md-1 order-2" id="basic-navbar-nav">
                        <Nav className="m-auto">
                          {
                            headerItems.map((item) => item.subItems? (
                                <NavDropdown
                                  key={item.name}
                                  className="ml-5 mr-2"
                                  id="nav-dropdown"
                                  title={<span className={styles.headerItem}>{item.name}</span>}
                                  onMouseEnter={() => headerItemToggle(item.name)}
                                  onMouseLeave={() => headerItemToggle(item.name)}
                                  show={item.name in headerItemOpen? headerItemOpen[item.name] : false}
                                >
                                  {
                                    item.subItems.map((subItem) => (
                                      <NavDropdown.Item href={subItem.url} key={subItem.name}>
                                        <Typography className={styles.headerItem}>
                                          {subItem.name}
                                        </Typography>
                                      </NavDropdown.Item>
                                    ))
                                  }
                                </NavDropdown>
                            ) : (
                                <Nav.Link className='ml-5 mr-2'
                                          href={item.url}
                                          key={item.name}
                                >
                                  <Typography className={styles.headerItem}>
                                    {item.name}
                                  </Typography>
                                </Nav.Link>
                              )
                            )
                          }
                        </Nav>
                    </Navbar.Collapse>
                    <Button
                      variant="outline-warning"
                      className="mr-md-3 order-md-2 order-1"
                      onClick={contactToggle}
                    >
                      <Typography className="font-weight-bold">联系我们</Typography>
                    </Button>
                  </div>
                </Navbar>

                <ScrollToTop />
                
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    {/*<Route exact path="/master-application">
                        <MasterApplication />
                    </Route>
                    <Route exact path="/undergraduate-application">
                        <UndergraduateApplication />
                    </Route>*/}
                    <Route exact path="/school-application">
                        <MasterApplication />
                    </Route>
                    <Route path="/job-hunting">
                        <JobHunting />
                    </Route>
                    <Route path="/mock-interview">
                        <MockInterview />
                    </Route>
                    <Route path="/resume-workshop">
                        <ResumeWorkshop />
                    </Route>
                    <Route exact path="/about-us">
                        <AboutUs />
                    </Route>
                    <Route exact path="/join-us">
                        <JoinUs />
                    </Route>
                    {
                      policies.map((policy) => (
                        <Route exact path={policy.link} key={policy.name}>
                            <PolicyTemplate policy={policy} />
                        </Route>
                      ))
                    }
                </Switch>

                <Footer />
            </Router>

            <ContactDialog
              open={contactOpen}
              openToggle={contactToggle}
            />
        </>
  );
};

export default Header;
