import { companyLogos } from './CompanyLogos';

export const jobOfferData = [
  {
    name: '学生平均获得offer',
    value: "3"
  },
  {
    name: '学生上岸率',
    value: "86%"
  },
  {
    name: 'New Grad平均起薪',
    value: "$105k"
  },
]

export const jobOffers = [
  {
    name: "X同学",
    background: "Georgia Tech | CS",
    offerCompany: companyLogos.amazon.name,
    offerCompanyImgLogo: companyLogos.amazon.logo,
    offerPosition: "SDE Intern",
  },
  {
    name: "W同学",
    background: "USC | CS",
    offerCompany: companyLogos.microsoft.name,
    offerCompanyImgLogo: companyLogos.microsoft.logo,
    offerPosition: "Software Development Engineer",
  },
  {
    name: "W同学",
    background: "Georgia Tech | CS",
    offerCompany: companyLogos.ncr.name,
    offerCompanyImgLogo: companyLogos.ncr.logo,
    offerPosition: "Software Development Engineer",
  },
  {
    name: "L同学",
    background: "USC | Applied Data Science",
    offerCompany: companyLogos.uber.name,
    offerCompanyImgLogo: companyLogos.uber.logo,
    offerPosition: "Data Analytics Intern",
  },
  {
    name: "Z同学",
    background: "Columbia | CS",
    offerCompany: companyLogos.veeva.name,
    offerCompanyImgLogo: companyLogos.veeva.logo,
    offerPosition: "Software Development Engineer",
  },
  {
    name: "M同学",
    background: "U of Washinton | CS",
    offerCompany: companyLogos.amazon.name,
    offerCompanyImgLogo: companyLogos.amazon.logo,
    offerPosition: "Software Development Engineer",
  },
  {
    name: "C同学",
    background: "MSU | CS",
    offerCompany: companyLogos.splunk.name,
    offerCompanyImgLogo: companyLogos.splunk.logo,
    offerPosition: "Software Development Engineer",
  },
  {
    name: "Z同学",
    background: "USC | CS",
    offerCompany: companyLogos.paloalto.name,
    offerCompanyImgLogo: companyLogos.paloalto.logo,
    offerPosition: "Software Engineer (Security Research)",
  },
  {
    name: "Y同学",
    background: "Georgia Tech | ECE",
    offerCompany: companyLogos.apple.name,
    offerCompanyImgLogo: companyLogos.apple.logo,
    offerPosition: "CPU RTL Engineer",
  },
  {
    name: "L同学",
    background: "Columbia U | ECE",
    offerCompany: companyLogos.amazon.name,
    offerCompanyImgLogo: companyLogos.amazon.logo,
    offerPosition: "Software Development Engineer",
  },
  {
    name: "Y同学",
    background: "Georgia Tech | ECE",
    offerCompany: companyLogos.nvidia.name,
    offerCompanyImgLogo: companyLogos.nvidia.logo,
    offerPosition: "ASIC Clocks Intern",
  },
  {
    name: "K同学",
    background: "CMU | ECE",
    offerCompany: companyLogos.google.name,
    offerCompanyImgLogo: companyLogos.google.logo,
    offerPosition: "Software Engineer",
  },
  {
    name: "N同学",
    background: "UMich | User experience design and research",
    offerCompany: companyLogos.mrm.name,
    offerCompanyImgLogo: companyLogos.mrm.logo,
    offerPosition: "Associate UX architect",
  },
  {
    name: "H同学",
    background: "USC | CS",
    offerCompany: companyLogos.apple.name,
    offerCompanyImgLogo: companyLogos.apple.logo,
    offerPosition: "Graphics Software Engineer",
  },
  {
    name: "L同学",
    background: "CMU | ECE",
    offerCompany: companyLogos.pinterest.name,
    offerCompanyImgLogo: companyLogos.pinterest.logo,
    offerPosition: "Software Engineer",
  },
  {
    name: "Y同学",
    background: "NYU | CS",
    offerCompany: companyLogos.amazon.name,
    offerCompanyImgLogo: companyLogos.amazon.logo,
    offerPosition: "Software Development Engineer",
  },
  {
    name: "G同学",
    background: "UW | CS",
    offerCompany: companyLogos.google.name,
    offerCompanyImgLogo: companyLogos.google.logo,
    offerPosition: "Software Engineer",
  },
  {
    name: "K同学",
    background: "NYU | CS",
    offerCompany: companyLogos.convoy.name,
    offerCompanyImgLogo: companyLogos.convoy.logo,
    offerPosition: "Software Engineer",
  },
  {
    name: "L同学",
    background: "USC | Applied Data Science",
    offerCompany: companyLogos.ebay.name,
    offerCompanyImgLogo: companyLogos.ebay.logo,
    offerPosition: "Data Analytics Intern",
  },
  {
    name: "Z同学",
    background: "MSU | Supply Chain Management",
    offerCompany: companyLogos.deloitte.name,
    offerCompanyImgLogo: companyLogos.deloitte.logo,
    offerPosition: "Risk and Financial Advisory Analyst",
  }
]
