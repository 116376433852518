export const aboutInterview = [
  {
    description: "测试一下",
    data: [
      {
        value: 10,
        color: "#FFBB28"
      },
      {
        value: 50,
        color: "#808080"
      }
    ],
    unit: "秒",
  },
  {
    description: "测试2下",
    data: [
      {
        value: 10,
        color: "#FFBB28"
      },
      {
        value: 50,
        color: "#808080"
      }
    ],
    unit: "%",
  },
  {
    description: "简历到不了HR手里就已被机器自动淘汰",
    data: [
      {
        value: 75,
        color: "#FFBB28"
      },
      {
        value: 25,
        color: "#808080"
      }
    ],
    unit: "%",
  }
]

export const questionsInterview = [
  "收到面试邀请，却不知道该如何准备？",
  "困惑从面试官角度，面试问题考察的重点是啥？",
  "不知道或者不能熟练掌握回答behavior questions的小技巧？",
  "困惑解答tech interview questions的流程和步骤有哪些？",
  "想在面试前，找人锻炼和完善面试过程中的细节?"
]

export const services = [
  {
    name: "SDE Tech Interview",
    summary: "和FLAG公司面试官1v1真实模拟，完善面试技巧和细节",
    description: "SDE Tech Interview其实看似是技术面试，其实更是通过技术面试考察你的方方面面。" +
                 "许多小伙伴只专注于解答技术问题，却忽略了很多面试官考察的小细节，" +
                 "而这些小细节往往很可能成为你面试成功与否的关键。在Tech的模拟面试中，" +
                 "来自FLAG公司的SDE面试官和你 1v1 真实还原技术面试，深入分析面试环节，" +
                 "以及面试官希望你在面试中回答技术问题的满分回答，" +
                 "助你完善Tech interview中技巧和细节，为真实的面试做足准备。" +
                 "如果你现在正在准备一场SDE技术面试，这个项目将是你的不二之选！",
    gain: [
      "tech面试的流程以及如何做好准备",
      "了解从面试官角度tech问题考察的重点有哪些",
      "熟练掌握tech面试中回答问题的技巧和方法",
      "完善面试技巧和细节，为真正的面试做好充足的准备"
    ]
  },
  {
    name: "Behavioral Interview",
    summary: "和FLAG公司面试官1v1真实模拟，完善面试技巧和细节",
    description: "Behavioral Interview看似是面试环节中最轻松的一个环节，" +
                 "但你知道面试官问Behavior Questions背后真正想要考察的点么？" +
                 "许多小伙伴在轻松通过了4-5轮的tech interview后，却在最后一轮的Behaviroal Interview里，与offer失之交臂。" +
                 "在Behavioral的模拟面试中，" +
                 "来自FLAG公司的SDE面试官和你 1v1 真实还原Behavioral Interview，深入分析Behavior Questions背后考察的点，" +
                 "以及该如何回答，让你轻松拿捏Behavioral Interview。如果你现在正在准备一场Behavioral Interview" +
                 "这个项目将助你一臂之力，斩获offer！",
    gain: [
      "准备面试中的behavior questions方法和技巧",
      "理解从面试官角度behavior question考察的重点有哪些",
      "熟练掌握behavior question的回答技巧和方法",
      "完善面试技巧和细节，为真正的面试做好充足的准备"
    ]
  }
]

export const workflow = [
  {
    name: "微信咨询",
    detail: "解答困惑，并了解学生背景和需求，有针对性地制定个性化课程内容"
  },
  {
    name: "预约导师",
    detail: "和导师沟通交流，预约时间进行模拟面试"
  },
  {
    name: "1v1模拟面试",
    detail: "与FLAG公司面试官进行1v1模拟面试，全程模拟真实面试过程"
  },
  {
    name: "模拟面试反馈",
    detail: "和导师讨论模拟面试过程中的优点以及有待提高的地方，完善每一个细节"
  }
]

export const generalQuestions = [
  {
    question: "test mock interview",
    answer: "This is the answer"
  },
  {
    question: "test",
    answer: "This is the answer"
  },
  {
    question: "test",
    answer: "This is the answer"
  },
  {
    question: "test",
    answer: "This is the answer"
  }
]
