import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
// import { PieChart, Pie, Cell } from 'recharts';
import ForwardIcon from '@material-ui/icons/Forward';
import ContactFloat from '../utils/ContactFloat';
import MaterialButton from '@material-ui/core/Button';
import Button from 'react-bootstrap/Button';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ContactDialog from '../utils/ContactDialog';
import { makeStyles } from '@material-ui/core/styles';
import jobQuestions from '../../imgs/Job_questions.png';
import jobProgramDescription from '../../imgs/Job_program_description.png';
import jobGain from '../../imgs/Job_gain.png';
import questions from '../../imgs/questions.jpg';
import PageTitleTemplate from '../utils/PageTitleTemplate';
import { companyLogos } from '../../business/CompanyLogos';

const useStyles = makeStyles((theme) => ({
  serviceButtons: {
   '& > *': {
     margin: theme.spacing(1, 3),
     fontSize: 16,
     fontWeight: 'bold',
     background: 'white',
     color: 'orange',
     borderRadius: '20px',
     border: '1px solid orange',
     textTransform: 'none',
     '&:hover': {
       background: 'orange',
       color: 'white',
     }
   },
  },
  serviceButtonSelected: {
   background: 'orange',
   color: 'black',
  }
}));

function JobTemplate({
  contents
}) {
  const styles = useStyles();
  const [contactOpen, setContactOpen] = useState(false);
  const contactToggle = () => {
    setContactOpen(!contactOpen);
  }

  const [service, setService] = useState(contents.program.services[0]);
  const companyLogoOrder = ["convoy", "amazon", "nvidia", "google", "splunk", "uber",
                            "pinterest", "ebay", "apple", "microsoft", "deloitte", "veeva"]

  return (
    <>
      <PageTitleTemplate
        background={contents.headerBackground}
        name={contents.header.title}
        slogan={contents.header.slogan}
      />

      <div className="jumbotron mb-0 py-5" style={{background: 'white'}}>
        <div className="container px-lg-5">
          <div className="row">
            {/*<h3>你知道吗</h3>
            <div className="row px-5">
              {
                contents.about.data.map((item, index) => (
                  <div className="col-lg-4 pt-md-4 pt-lg-3" key={item.description}>
                    <div className="row pl-5">
                        <PieChart className="pt-0" width={80} height={100}>
                          <Pie
                            data={item.data}
                            dataKey="value"
                            cx="50%"
                            cy="50%"
                            outerRadius={40}
                            startAngle={index === contents.about.data.length-1? 180 : 0}
                            endAngle={index === contents.about.data.length-1? 540 : 360}
                          >
                            {
                              item.data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.color} />
                              ))
                            }
                          </Pie>
                        </PieChart>
                        <div className="pl-2 pt-5">
                          <h1 className="d-inline pr-2">{item.data[0].value}</h1>
                          <h6 className="d-inline">{item.unit}</h6>
                        </div>
                    </div>
                    <div className="row pl-5">
                      <h5 className="pt-2">{item.description}</h5>
                    </div>
                  </div>
                ))
              }
            </div>*/}

            <div className="col-lg-4 offset-md-1">
              <img src={jobQuestions} alt="concerns" />
            </div>
            <div className="col-lg-6 my-auto">
              <h3 className="mb-3 text-center text-lg-left">你是否：</h3>
              {
                contents.about.questions.map((question, index) => (
                  <h6 className="my-2 ml-lg-5 text-center text-lg-left" key={`interview-question-${index}`}> {question} </h6>
                ))
              }
              <div className="text-center mt-3">
                <MoreVertIcon/>
                <h5 className="mt-3"> 不 怕，我 们 get you covered！</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="jumbotron mb-0" style={{background: 'white'}}>
        <div className="container px-lg-5">
          <h2 className="text-center">{contents.program.title}</h2>
          {contents.program.services.length > 1 && (
            <div className={`text-center ${styles.serviceButtons}`}>
              {contents.program.services.map((item, index) => (
                <MaterialButton
                  key={item.name}
                  className={service.name===item.name? styles.serviceButtonSelected : ""}
                  variant="outlined"
                  onClick={(e)=>setService(contents.program.services[index])}
                >
                  {item.name}
                </MaterialButton>
              ))}
            </div>
          )}

          <div className="row my-4 px-lg-5">
            <div className="col-lg-4 order-lg-1 my-auto">
              <img src={jobProgramDescription} alt="Job Program Description" />
            </div>
            <div className="col-lg-8 order-md-0 mt-lg-0">
              <h5>{service.summary}</h5>
              <p>{service.description}</p>
            </div>
          </div>

          <div className="row mt-5 pt-5">
            <div className="col-lg-4 offset-md-1">
              <img src={jobGain} alt="concerns" />
            </div>
            <div className="col-lg-6 my-auto">
              <h3 className="mb-3 text-center text-lg-left">你将收获</h3>
              {
                service.gain.map((g, index) => (
                  <h6 className="my-2 ml-lg-5 text-lg-left" key={`interview-question-${index}`}>
                    <CheckBoxIcon className="mr-2" style={{color: 'orange'}}/>
                    {g}
                  </h6>
                ))
              }

            </div>
          </div>
        </div>
      </div>

      {/*<div className="jumbotron mb-0">
        <div className="container px-5">
          <h2 className="text-center">特点/特色</h2>

          <div className="row px-5 pt-4">
            {
              chosenReasons.map((reason) => (
                <div className="col-md-6" key={reason.name}>
                  <div className="row">
                    <div className="col">
                      <p>图标</p>
                    </div>
                    <div className="col">
                      <h5 style={{color: '#ff9900', fontWeight: 'bold'}}>{reason.name} </h5>
                      <p> {reason.description} </p>
                    </div>
                  </div>
                </div>
              ))
            }

          </div>
        </div>

      </div>*/}

      <div className="jumbotron mb-0" style={{background: 'white'}}>
        <div className="container px-lg-5">
          <h2 className="text-center">我们的学员已成功进入:</h2>
          <div className="row">
            {
              companyLogoOrder.map((company) => (
              <div key={company} className="col-4 col-sm-3 col-md-3 col-lg-2  px-3 mt-3">
                <img src={companyLogos[company].logo} alt={companyLogos[company].name} />
              </div>
            ))
            }
          </div>
          <div className="text-center mt-3">
            <MoreVertIcon/>
          </div>
        </div>
      </div>

      <div className="jumbotron" style={{background: 'white'}}>
        <div className="container px-lg-5">
          <h2 className="text-center">{contents.workflow.title}</h2>

          <div className="row mt-4 px-lg-5">
          {
            contents.workflow.steps.map((step, index) => (
              <div className="col-lg-3" key={step.name}>
                <div className="row">
                  <div className="col-md-3 d-lg-none">
                  </div>
                  <div className="col-md-6 col-lg-9 px-0">
                    <Paper className="px-3 py-3" elevation={3} style={{border: '2px solid orange'}}>
                      <h5 className="mb-0 text-center">{step.name}</h5>
                      <p className="mb-0 pt-2">{step.detail}</p>
                    </Paper>
                  </div>
                  {
                    index !== contents.workflow.steps.length - 1 && (
                      <div className="col-lg-3 px-0 text-center">
                        <ForwardIcon className="d-none d-lg-block" style={{color: 'orange', width: '100%', height: 'auto'}}/>
                        <ForwardIcon className="d-lg-none" style={{color: 'orange', width: '60px', height: 'auto', transform: 'rotate(90deg)'}}/>
                      </div>
                    )
                  }
                </div>
              </div>
            ))
          }
          </div>
        </div>
      </div>

      <div style={{
                    background: `linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.4)),url(${questions})`,
                    minHeight: '220px',
                    color: '#fff',
                    fontWeight: 600,
                    fontSize: '48px',
                  }}>
        <div className="container-fluid text-center pt-5">
          <h2>还有问题?</h2>
          <div className="text-center">
            <Button
              variant="warning"
              onClick={contactToggle}
              style={{borderRadius: '25px', padding: '5px 20px', fontWeight: 500, fontSize: '20px'}}
            >
              点击这里立即咨询
            </Button>
          </div>
        </div>
      </div>

      <ContactDialog
        open={contactOpen}
        openToggle={contactToggle}
      />
      <ContactFloat />
    </>
  )
}

export default JobTemplate;
