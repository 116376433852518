import React from 'react';

function PolicyTemplate({ policy }) {

  return (
    <>
      <div className="container py-5">
        <h3 className="text-center mb-4"> {policy.name} </h3>
        {
          policy.description.map((sentence) => (
            <p>{sentence}</p>
          ))
        }
        {
          policy.contents.map((paragraph) => (
            <React.Fragment>
              <h5> {paragraph.title} </h5>
              <p> {paragraph.description} </p>
            </React.Fragment>
          ))
        }
      </div>
    </>
  )
}

export default PolicyTemplate;
