import amazon from '../imgs/companyLogos/amazon.jpg';
import apple from '../imgs/companyLogos/apple.jpg';
import convoy from '../imgs/companyLogos/convoy.jpg';
import deloitte from '../imgs/companyLogos/deloitte.jpg';
import ebay from '../imgs/companyLogos/ebay.jpg';
import google from '../imgs/companyLogos/google.jpg';
import microsoft from '../imgs/companyLogos/microsoft.jpg';
import mrm from '../imgs/companyLogos/mrm.jpg';
import ncr from '../imgs/companyLogos/ncr.jpg';
import nvidia from '../imgs/companyLogos/nvidia.jpg';
import paloalto from '../imgs/companyLogos/paloalto.jpg';
import pinterest from '../imgs/companyLogos/pinterest.jpg';
import splunk from '../imgs/companyLogos/splunk.jpg';
import uber from '../imgs/companyLogos/uber.jpg';
import veeva from '../imgs/companyLogos/veeva.jpg';

export const companyLogos = {
  amazon: {
    name: "Amazon",
    logo: amazon
  },
  apple: {
    name: "Apple",
    logo: apple
  },
  convoy: {
    name: "Convoy",
    logo: convoy
  },
  deloitte: {
    name: "Deloitte",
    logo: deloitte
  },
  ebay: {
    name: "eBay",
    logo: ebay
  },
  google: {
    name: "Google",
    logo: google
  },
  microsoft: {
    name: "Microsoft",
    logo: microsoft
  },
  mrm: {
    name: "MRM",
    logo: mrm
  },
  ncr: {
    name: "NCR",
    logo: ncr
  },
  nvidia: {
    name: "NVIDIA",
    logo: nvidia
  },
  paloalto: {
    name: "Palo Alto Network",
    logo: paloalto
  },
  pinterest: {
    name: "Pinterest",
    logo: pinterest
  },
  splunk: {
    name: "Splunk",
    logo: splunk
  },
  uber: {
    name: "Uber",
    logo: uber
  },
  veeva: {
    name: "Veeva Systems",
    logo: veeva
  }
}
