import React from 'react';
import {  Link } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
import { makeStyles } from '@material-ui/core/styles';
// import { serviceItems } from '../../business/ServiceItems';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ContactFloat from '../utils/ContactFloat';

import {
  landingSliderImages,
  trustUsReasons,
  schoolApplicationChosenReasons,
  jobChosenReasons
} from '../../business/HomePage';
import {
  jobOfferData,
  jobOffers,
} from '../../business/JobOffers';
import { applicationData, schoolApplicationOffers } from '../../business/SchoolApplicationOffers';
import { headerItems } from '../../business/HeaderItems';
import { company } from '../../business/CompanyInfo';
import './HomeStyle.scss';
import chosen_reason_image from '../../imgs/Home_Customer-Obsession.jpg';

const useStyles = makeStyles((theme) => ({
  img: {
    width: '100%',
    height: 'auto',
  },
  marquee: {
    background: 'red'
  },
  serviceButtons: {
    '& > *': {
      margin: theme.spacing(1, 3),
      width: 150,
      fontSize: 19,
      fontWeight: 'bold',
      background: 'white',
      color: 'orange',
      borderRadius: '20px',
      border: '1px solid orange',
      '&:hover': {
        background: 'orange',
        color: 'white',
      }
    },
  }
}));

function Home() {
  const styles = useStyles();

  return (
    <>
        <Carousel variant="dark">
          {
            landingSliderImages.map((img) => (
              <Carousel.Item key={img.alt} interval={4000}>
              <Link to={img.link}>
                <img
                  className={styles.img}
                  src={img.src}
                  alt={img.alt}
                />
              </Link>
              </Carousel.Item>
            ))
          }
        </Carousel>

        <div className="jumbotron" style={{background: 'white'}}>
            <div className="container">
              <div className="text-center">
                <h2>{company.name} 助你扬帆起航</h2>
                <h6>持续更新中</h6>
              </div>

              <div className="row bg-gold mt-4">
                <div className="col-md-6 px-4">
                  <Paper className="my-4" levation={4}>
                    <div className="pt-3 px-4 text-center border-text">
                      <div className="border_left"></div>
                      <h3 className="border-text-title">
                          留学申请战绩
                      </h3>
                      <div className="border_right"></div>
                    </div>

                    <div className="container">
                      <Paper className="school-data px-4 pt-2 text-center">
                        <h4>
                          录取数据
                        </h4>
                      </Paper>

                      <div className="pt-5 school-data-content">
                        <div className="row">
                            {
                              applicationData.map((data, idx) => (
                                <div key={idx} className={`text-center col-md-4 col-lg-4 col-sm-4 col-xs-4 ${idx===applicationData.length-1? "" : "border-right"}`}>
                                  <h6>
                                    {data.name}
                                  </h6>
                                  <h3>
                                    {data.value}
                                  </h3>
                                </div>
                              ))
                            }
                        </div>
                      </div>
                    </div>

                    <div className="container">
                      <Paper className="school-offer px-4 pt-2 text-center">
                        <h4>
                          录取offer榜
                        </h4>
                      </Paper>
                      <div className="mt-3 marquee">
                        <div className="marquee-content">
                        {
                          schoolApplicationOffers.map((offer, idx) => (
                            <div className="row border border-top-0 px-4" key={idx}>
                              <div className="col-md-4 col-xs-6 col-sm-6 py-2 my-auto">
                                <img
                                  className={styles.img}
                                  src={offer.offerSchoolImgLogo}
                                  alt={`${offer.name} ${offer.offerSchool} Offer`}
                                />
                              </div>
                              <div className="col-md-8 col-xs-6 col-sm-6 py-2">
                                <p className="mb-0">学生姓名: &nbsp; {offer.name}</p>
                                <p className="mb-0">来自学校: &nbsp; {offer.school}</p>
                                <p className="mb-0">录取学校: &nbsp; {offer.offerSchool}</p>
                                <p className="mb-0">录取专业: &nbsp; {offer.offerMajor}</p>
                              </div>
                            </div>
                          ))
                        }
                        </div>
                        <div className="marquee-content">
                        {
                          schoolApplicationOffers.map((offer, idx) => (
                            <div className="row border border-top-0 px-4" key={idx}>
                              <div className="col-md-4 col-xs-6 col-sm-6 py-2 my-auto">
                                <img
                                  className={styles.img}
                                  src={offer.offerSchoolImgLogo}
                                  alt={`${offer.name} ${offer.offerSchool} Offer`}
                                />
                              </div>
                              <div className="col-md-8 col-xs-6 col-sm-6 py-2">
                                <p className="mb-0">学生姓名: &nbsp; {offer.name}</p>
                                <p className="mb-0">来自学校: &nbsp; {offer.school}</p>
                                <p className="mb-0">录取学校: &nbsp; {offer.offerSchool}</p>
                                <p className="mb-0">录取专业: &nbsp; {offer.offerMajor}</p>
                              </div>
                            </div>
                          ))
                        }
                        </div>
                      </div>
                      <div className="pb-4"></div>
                    </div>
                  </Paper>
                </div>

                <div className="col-md-6 px-4">
                  <Paper className="my-4" levation={4}>
                    <div className="pt-3 px-4 text-center border-text">
                      <div className="border_left"></div>
                      <h3 className="border-text-title">
                          求职成功案例
                      </h3>
                      <div className="border_right"></div>
                    </div>

                    <div className="container">
                      <Paper className="school-data px-4 pt-2 text-center">
                        <h4>
                          offer数据
                        </h4>
                      </Paper>

                      <div className="pt-5 school-data-content">
                        <div className="row">
                            {
                              jobOfferData.map((data, idx) => (
                                <div key={idx} className={`text-center col-md-4 col-lg-4 col-sm-4 col-xs-4 ${idx===applicationData.length-1? "" : "border-right"}`}>
                                  <h6>
                                    {data.name}
                                  </h6>
                                  <h3>
                                    {data.value}
                                  </h3>
                                </div>
                              ))
                            }
                        </div>
                      </div>
                    </div>

                    <div className="container">
                      <Paper className="school-offer px-4 pt-2 text-center">
                        <h4>
                          求职offer榜
                        </h4>
                      </Paper>
                      <div className="mt-3 marquee">
                        <div className="job-marquee-content">
                        {
                          jobOffers.map((offer, idx) => (
                            <div key={idx} className="row border border-top-0 py-2 px-4">
                              <div className="col-md-4 col-xs-6 col-sm-6 py-2 my-auto">
                                <img
                                  className={styles.img}
                                  src={offer.offerCompanyImgLogo}
                                  alt={`${offer.name} ${offer.offerCompany} ${offer.offerPosition} Offer`}
                                />
                              </div>
                              <div className="col-md-8 col-xs-6 col-sm-6 py-2">
                                <p className="mb-0">学生姓名: &nbsp; {offer.name}</p>
                                <p className="mb-0">学生背景: &nbsp; {offer.background}</p>
                                <p className="mb-0">offer公司: &nbsp; {offer.offerCompany}</p>
                                <p className="mb-0">offer职位: &nbsp; {offer.offerPosition}</p>
                              </div>
                            </div>
                          ))
                        }
                        </div>
                        <div className="job-marquee-content">
                        {
                          jobOffers.map((offer, idx) => (
                            <div key={idx} className="row border border-top-0 py-2 px-4">
                              <div className="col-md-4 col-xs-6 col-sm-6 py-2 my-auto">
                                <img
                                  className={styles.img}
                                  src={offer.imgSrc}
                                  alt={offer.imgAlt}
                                />
                              </div>
                              <div className="col-md-8 col-xs-6 col-sm-6 py-2">
                                <p className="mb-0">学生姓名: &nbsp; {offer.name}</p>
                                <p className="mb-0">学生背景: &nbsp; {offer.background}</p>
                                <p className="mb-0">offer公司: &nbsp; {offer.offerCompany}</p>
                                <p className="mb-0">offer职位: &nbsp; {offer.offerPosition}</p>
                              </div>
                            </div>
                          ))
                        }
                        </div>
                      </div>
                      <div className="pb-4"></div>
                    </div>
                  </Paper>
                </div>
              </div>
            </div>
        </div>

        <div className="jumbotron">
          <div className="text-center">
            <h2>为什么信任 {company.name}</h2>
          </div>
          <div className="container px-lg-5">
            <div className="row px-lg-5">
              <div className="col-lg-7 mt-3">
                {
                  trustUsReasons.map((reason) => (
                    <p key={reason} style={{whiteSpace: 'pre-wrap'}}>
                      {reason}
                    </p>
                  ))
                }
              </div>
              <div className="col-lg-5 mt-3">
                <img className={styles.img} src={chosen_reason_image} alt="信任佳一"/>
              </div>
            </div>
          </div>
        </div>

        <div className="jumbotron" style={{background: 'white'}}>
          <div className="text-center">
            <h2>留学申请服务</h2>
            <div className={styles.serviceButtons}>
              {
                headerItems[1].subItems && headerItems[1].subItems.map((item) => (
                  <Button key={item.name} variant="outlined" href={item.url}>{item.name}</Button>
                ))
              }
            </div>
          </div>

          <div className="container px-lg-5">
            <div className="row">
            {
              schoolApplicationChosenReasons.map((reason, i) => (
                <div className='col-lg-3 my-auto' key={reason.title}>
                  <Paper elevation={3} className="service-card-title pt-2 text-center">
                    <h4>优势 {i+1}</h4>
                  </Paper>
                  <div className="card">
                    <div className="card-body pb-0">
                      <h5 className="card-title pt-4">{reason.title}</h5>
                      <p>{reason.description}</p>
                    </div>
                  </div>
                </div>
              ))
            }
            </div>
          </div>
        </div>

        <div className="jumbotron" style={{background: 'white'}}>
          <div className="text-center">
            <h2>求职服务</h2>
            <div className={styles.serviceButtons}>
              {
                headerItems[2].subItems.map((item) => (
                  <Button key={item.name} variant="outlined" href={item.url}>{item.name}</Button>
                ))
              }
            </div>
          </div>

          <div className="container px-lg-5">
            <div className="row">
            {
              jobChosenReasons.map((reason, i) => (
                <div className='col-lg-3' key={reason.title}>
                  <Paper elevation={3} className="service-card-title pt-2 text-center">
                    <h4>优势 {i+1}</h4>
                  </Paper>
                  <div className="card">
                    <div className="card-body pb-0">
                      <h5 className="card-title pt-4">{reason.title}</h5>
                      <p>{reason.description}</p>
                    </div>
                  </div>
                </div>
              ))
            }
            </div>
          </div>
        </div>
        <ContactFloat />
    </>
  )
}

export default Home;
