import React from 'react';
import JobTemplate from '../JobTemplate';
import headerBackground from '../../../imgs/MockInterview-header-background.jpg';

import { aboutInterview,
         questionsInterview,
         workflow,
         services
       } from '../../../business/MockInterviewPage';

function MockInterview() {

  const contents = {
    headerBackground: headerBackground,
    header: {
      title: "模拟面试",
      slogan: "与导师1对1个性化面试辅导，解决面试疑难杂症"
    },
    about: {
      title: "关于面试",
      data: aboutInterview,
      questions: questionsInterview
    },
    program: {
      title: "项目简介",
      services: services
    },
    workflow: {
      title: "流程",
      steps: workflow
    }
  }

  return (
    <>
      <JobTemplate contents={contents} />
    </>
  )
}

export default MockInterview;
