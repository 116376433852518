import React from 'react';

function PageTitleTemplate({
  name,
  background,
  slogan
}) {

  return (
    <div style={{
                  background: `linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.4)),url(${background})`,
                  minHeight: '250px',
                  BackgroundSize: 'cover',
                  OBackgroundSize: "cover",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  WebkitBackgroundSize: "cover",
                }}>
      <div className="container-fluid text-center" style={{
        color: '#fff',
        fontWeight: 600,
        paddingTop: '11%',
        fontSize: '48px',
        lineHeight: '0px',
      }}>
        <h1>
          {name}
        </h1>
        <h5>
          {slogan}
        </h5>
      </div>
    </div>
  )
}

export default PageTitleTemplate;
