import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { makeStyles } from '@material-ui/core/styles';
import { company } from '../../../business/CompanyInfo';

const useStyles = makeStyles(theme => ({
  title: {
    color: 'orange',
  },
  button: {
    background: 'orange',
    color: 'white',
    fontWeight: 500,
    '&:hover': {
      background: 'orange'
    }
  }
}));

function JobDialog(props) {
  const styles = useStyles();

  return props.job && (
    <React.Fragment>
    <Modal show={props.open} onHide={props.openToggle} centered>
        <Modal.Header closeButton>
          <Modal.Title className={styles.title}>{props.job.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="my-1"><strong style={{fontWeight: 800}}>工作地点:</strong> &nbsp; {props.job.location}</p>
          <p className="my-1"><strong style={{fontWeight: 800}}>工作性质: </strong> &nbsp;{props.job.type}</p>
          <p className="my-1"><strong style={{fontWeight: 800}}>工作职责: </strong></p>
          <ol className="my-1">
          {
            props.job.responsibilities && props.job.responsibilities.map((res) => (
              <li key={res}>{res}</li>
            ))
          }
          </ol>
          <p className="my-1"><strong style={{fontWeight: 800}}>职位要求: </strong></p>
          <ol>
            {
              props.job.requirements && props.job.requirements && props.job.requirements.map((req) => (
                <li key={req}>{req}</li>
              ))
            }
          </ol>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="warning"
            className={styles.button}
            href={`mailto:${company.hrEmail}`}
          >
            立即申请
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

export default JobDialog;
