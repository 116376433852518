import React, { useState } from 'react';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import ContactDialog from '../utils/ContactDialog';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
// import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import ContactFloat from '../utils/ContactFloat';
import CommonQuestions from '../utils/CommonQuestions';
import PageTitleTemplate from '../utils/PageTitleTemplate';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import background from '../../imgs/School_header_background.jpg';
import './ApplicationStyle.css';
import { applicationData, schoolApplicationOffers } from '../../business/SchoolApplicationOffers';
import { company } from '../../business/CompanyInfo';

// function SplitOffersByNum(offers, num) {
//   const res = [];
//   for (var i = 0; i < offers.length; i+=num) {
//     res.push(offers.slice(i, i+num));
//   }
//   return res;
// }

function ApplicationTemplate({
         name,
         chosenReasons,
         applicationPrograms,
         programFlows,
         generalQuestions
       }) {
  const [contactOpen, setContactOpen] = useState(false);
  // const schoolOfferGroups = SplitOffersByNum(schoolApplicationOffers, 2);
  const [offer, setOffer] = useState({});
  const [offerOpen, setOfferOpen] = useState(false);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const contactToggle = () => {
    setContactOpen(!contactOpen);
  }

  const closeOfferDetail = () => {
    setOfferOpen(!offerOpen);
    setOffer({});
  }
  const openOfferDetail = (selectedOffer) => {
    setOffer(selectedOffer);
    setOfferOpen(!offerOpen);
  }

  const flowTableBody = programFlows.map((flow, i) => {
    const displayStage = (
      <th
        className="align-middle"
        rowSpan={flow.services.length}
        style={{fontSize: '18px'}}
      >
        {flow.stage}
      </th>
    )
    const displayProgramFlow = flow.services.map((service, i) => {
      const displayProgramValue = service.programValues.map((val, ind) => (
        <td className="align-middle" key={`${service.name}+${i}+${ind}`}>
          {
            val===1 && (<CheckIcon style={{color: 'orange', fontSize: '30px'}} />)
          }
        </td>
      ))

      return (
        <tr key={service.name}>
          {i===0 && displayStage}
          <td className="pb-0 px-3 py-2">
            <h6>{service.name}</h6>
            <p className="mb-0 text-left">{service.description}</p>
          </td>
          {displayProgramValue}
        </tr>
      )
    })

    return (
      <React.Fragment key={flow.stage}>
        {displayProgramFlow}
      </React.Fragment>
    )
  })

  return (
    <>
      <PageTitleTemplate
        name={name}
        slogan="定制服务，赢在起跑线，轻松踏入梦校"
        background={background}
      />

      <div className="jumbotron" style={{background: 'white'}}>
            <div className="text-center">
              <h2>名校录取战绩</h2>
              <h6>持续更新中</h6>
            </div>
          <div className="container">
            <div className="row bg-gold mt-4">
              <div className="col-lg-3 pl-4">
                <Paper className="my-4 py-2" elevation={4}>
                  <div className="container text-center">
                    <div className="mt-2">
                      <h4 className="offer-board-title py-2">录取数据</h4>
                    </div>

                    <div className="school-data-board-content">
                      <div className="row">
                          {
                            applicationData.map((data, idx) => (
                              <div key={idx} className={`text-center col-sm-4 col-md-4 col-lg-12 ${idx===applicationData.length-1? "" : "border-right"}`}>
                                <h6>
                                  {data.name}
                                </h6>
                                <h3>
                                  {data.value}
                                </h3>
                              </div>
                            ))
                          }
                      </div>
                    </div>
                  </div>
                </Paper>
              </div>
              <div className="col-lg-9 pr-4">
                <Paper className="my-4 py-2" elevation={4}>
                  <span className="offer-board-ping top-left-ping"></span>
                  <span className="offer-board-ping top-right-ping"></span>

                  <div className="container py-4 px-4">
                    <Carousel
                      responsive={responsive}
                      infinite
                      autoPlay
                      draggable={false}
                      swipeable={false}
                      renderButtonGroupOutside={true}
                      autoPlaySpeed={5000}
                      slidesToSlide={1}
                    >
                      {
                        schoolApplicationOffers.map((offer, ind) => (
                          <div className="px-3" key={ind}>
                            <Button
                              onClick={()=> openOfferDetail(offer)}
                              className="px-0 py-0"
                              style={{background: 'white', border: 'none'}}
                            >
                            <div className="card">
                              <div className="card-body">
                                <Paper elevation={4}>
                                  <img
                                    className="offer-image "
                                    src={offer.offerImgSrc}
                                    alt={offer.imgAlt}
                                  />
                                </Paper>
                                <div className="offer-coverlay px-2 py-2">
                                  <p className="py-0 my-0"><b style={{fontSize: '17px'}}>{offer.name}</b></p>
                                  <p className="py-0 my-0">录取: <b style={{fontSize: '17px'}}>{offer.offerSchool}</b></p>
                                </div>
                              </div>
                            </div>
                            </Button>
                          </div>
                        ))
                      }
                    </Carousel>
                    {/*<Carousel indicators={false}>
                      {
                        schoolOfferGroups.map((group, ind) => (
                          <Carousel.Item key={ind}>
                            <div className="row px-5">
                            {
                              group.map((offer, ind) => (
                                <div className={`col-md-5 ${!ind%2 && "offset-md-1"} ${ind%2 && "pt-3 pt-md-0"} px-4`} key={offer.imgAlt}>
                                  <Button
                                    onClick={()=> openOfferDetail(offer)}
                                    className="px-0 py-0"
                                    style={{background: 'white', border: 'none'}}
                                  >
                                  <div className="card">
                                    <div className="card-body">
                                      <Paper elevation={4}>
                                        <img
                                          className="offer-image "
                                          src={offer.offerImgSrc}
                                          alt={offer.imgAlt}
                                        />
                                      </Paper>
                                      <div className="offer-coverlay px-2 py-2">
                                        <p className="py-0 my-0"><b style={{fontSize: '17px'}}>{offer.name}</b></p>
                                        <p className="py-0 my-0">录取: <b style={{fontSize: '17px'}}>{offer.offerSchool}</b></p>
                                      </div>
                                    </div>
                                  </div>
                                  </Button>
                                </div>
                              ))
                            }
                            </div>
                          </Carousel.Item>
                        ))
                      }
                    </Carousel>*/}
                  </div>
                  <span className="offer-board-ping bottom-left-ping"></span>
                  <span className="offer-board-ping bottom-right-ping"></span>
                </Paper>
              </div>
            </div>
          </div>
      </div>

      <div className="jumbotron" style={{background: '#fff', margin: 0}}>
        <div className="text-center">
          <h2>选择{company.name}留学申请的6大理由</h2>
        </div>

        <div className="container">
          <ol>
            <div className="row">
              {
                chosenReasons.map((reason) => (
                  <li className="item col-md-4" key={reason.name}>
                      <h4 className="headline">{reason.name}</h4>
                      <span>
                      {reason.description}
                      </span>
                  </li>
                ))
              }
            </div>
          </ol>
        </div>
      </div>

      <div className="jumbotron" style={{background: 'white'}}>
        <div className="text-center">
          <h2>个性化需求匹配</h2>
        </div>
        <div className="row mt-4">
        {
          applicationPrograms.map((program, index) => (
            <div className={`col-md-4 ${index%2? "": 'offset-md-2'} mt-4`} key={program.name}>
              <div className='h-100 card' style={{borderRadius: '15px'}}>
                {index === applicationPrograms.length-1 && (
                  <div className="ribbon">
                    <div className="wrap">
                      <span className="ribbon6">开学季限时优惠</span>
                    </div>
                  </div>
                )}
                <div className="card-body d-flex flex-column">
                  <div className={`row pt-2 mx-1 card-title-${index}`}>
                    <span className="circle left-circle"></span>
                    <div className="col text-center">
                      <h4 className="text-center">
                        {index===applicationPrograms.length-1 && <WhatshotIcon style={{color: 'red'}}/>}
                        &nbsp; {program.name} &nbsp;
                        {index===applicationPrograms.length-1 && <WhatshotIcon style={{color: 'red'}}/>}
                      </h4>
                    </div>
                    <span className="circle right-circle"></span>
                  </div>
                  <p className="card-text mt-4">{program.description}</p>
                  <h6>适合人群：</h6>
                  {
                    program.fitPeople.map((people, index) => (
                      <p className={`${index===program.fitPeople.length-1? "mb-4" : "mb-0"}`} key={people}>{index+1}. {people}</p>
                    ))
                  }
                  <div className="text-center mt-auto">
                    <Button
                      className=""
                      variant="warning"
                      onClick={contactToggle}
                      style={{borderRadius: '25px', padding: '5px 20px', background: 'orange', fontWeight: 500}}
                    >
                      立即咨询
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ))
        }
        </div>
      </div>

      <div className="jumbotron" style={{background: '#fafafa'}}>
        <div className="text-center">
          <h2>服务流程</h2>
          <div className="container px-lg-3">
            <table className="table-responsive table-bordered mt-4">
              <thead className="thead-dark" style={{fontSize: '20px'}}>
                <tr>
                  <th style={{width: '5%'}}></th>
                  <th style={{width: '35%'}}>服务内容</th>
                  {
                    applicationPrograms.map((program) => (
                      <th style={{width: '15%'}} key={program.name}>{program.name}</th>
                    ))
                  }
                </tr>
              </thead>
              <tbody>
                {flowTableBody}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <CommonQuestions generalQuestions={generalQuestions} contactToggle={contactToggle} />

      <ContactDialog
        open={contactOpen}
        openToggle={contactToggle}
      />

      <Dialog
          open={offerOpen}
          onClose={closeOfferDetail}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
        >
        <DialogTitle id="alert-dialog-title" disableTypography>
          <Typography variant="h4">{offer.name}</Typography>
          <IconButton
            style={{right: '12px', top: '8px', position: 'absolute'}}
            onClick={closeOfferDetail}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <div className="container mt-3 pb-5">
            <div className="container-fluid px-0">
              <p className="offer-detail-header"><span> 背景 </span></p>
              <div className="offer-details">
                <p className="mb-0 pl-3 pl-lg-5 pt-2"><b>来自学校: </b>&nbsp; {offer.school}</p>
                <p className="mb-0 pl-3 pl-lg-5"><b>学术成绩: </b>&nbsp; GPA {offer.gpa}</p>
                <p className="mb-0 pl-3 pl-lg-5"><b>录取学校: </b>&nbsp; {offer.offerSchool}</p>
                <p className="mb-0 pl-3 pl-lg-5"><b>录取专业: </b>&nbsp; {offer.offerMajor}</p>
                {offer.scholarship && (
                  <p className="mb-0 pl-3 pl-lg-5"><b>奖学金: </b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {offer.scholarship}</p>
                )}
              </div>
            </div>

            <div className="container-fluid px-0 mt-4 mt-lg-5">
              <p className="offer-detail-header"><span> offer展示 </span></p>
              <Paper elevation={4} className="px-3 px-lg-4 pt-3 pt-lg-4 mt-4">
                <img src={offer.offerImgSrc} alt={offer.imgAlt}/>
              </Paper>
            </div>
          </div>
        </DialogContent>

      </Dialog>
      <ContactFloat />
    </>
  )
}

export default ApplicationTemplate;
