import React from 'react';
import {  Link } from "react-router-dom";
import { headerItems } from '../../business/HeaderItems';
import { company } from '../../business/CompanyInfo';
// import { policies } from '../../business/Policy';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    background: '#343A40',
    color: 'white'
  },
  footerItem: {
    fontSize: '14px',
    fontWeight: 500,
    color: 'gray',
    '&:hover': {
      color: '#cccccc',
      textDecoration: 'none'
    }
  },
  copyright: {
    fontSize: '14px'
  }
}));

function Footer() {
  const styles = useStyles();

  return (
    <>
      <footer className={`pt-4 ${styles.root}`}>
        <div className="container w-75 text-md-left pt-3">
          <div className="row px-md-5">
            {
              headerItems.slice(1).map((item) => (
                <div className="col-md-3 mb-md-0 mb-3" key={item.name}>
                  <h6>{item.name}</h6>
                  <ul className="list-unstyled">
                    {
                      item.subItems ? item.subItems.map((subItem) => (
                        <li key={subItem.name}>
                          <Link to={subItem.url} className={styles.footerItem}>
                            {subItem.name}
                          </Link>
                        </li>
                      )) : (
                        <li key={item.name}>
                          <Link to={item.url} className={styles.footerItem}>
                            {item.name}
                          </Link>
                        </li>
                      )
                    }
                  </ul>
                </div>
              ))
            }

            {/*<div className="col-md-3 mb-md-0 mb-3">
              <h6 className="text-uppercase">条款与政策</h6>
              <ul className="list-unstyled">
                {
                  policies.map((policy) => (
                    <li key={policy.name}>
                      <Link className={styles.footerItem} to={policy.link}>
                        {policy.name}
                      </Link>
                    </li>
                  ))
                }
              </ul>
            </div>*/}

            <div className="col-md-3 mt-md-0 mt-3">
              <h6>联系我们</h6>
              <p className={`mb-1 ${styles.copyright}`}> 微信咨询：{company.wechatId}</p>
              <img className="w-50" src={company.wechatImg} alt={`Wechat ID: ${company.wechatId}`} />
            </div>
          </div>
        </div>
        <div className="text-center py-2 pt-3">
          <p className={styles.copyright}>&copy;{new Date().getFullYear()} {company.name} | All rights reserved</p>
        </div>
      </footer>
    </>
  )
}

export default Footer;
