import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { company } from '../../business/CompanyInfo';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    top: '50%',
    left: 0,
    borderRadius: '20px',
    zIndex: 100,
  },
  contact: {
    width: '130px',

  }
}));

function ContactFloat() {
  const styles = useStyles();

  return (
    <>
      <div className={styles.root}>
        <Paper className={`d-none d-md-block ${styles.contact}`} elevation={5}>
          <div className="container text-center py-2">
            <h6 style={{fontSize: '16px'}}>官方微信咨询</h6>
            <img
              src={company.wechatImg}
              alt={`wechat ID: ${company.wechatId}`}
              style={{
                margin: 'auto',
                display: 'block',
                width: '100%',
                height: 'auto',
              }}
            />
          </div>
        </Paper>
      </div>
    </>
  )
}

export default ContactFloat;
