import { FaThumbsUp,  FaHandHoldingHeart } from 'react-icons/fa';
import { IoIosPeople } from "react-icons/io";

export const aboutUs = {
  vision: "佳一未来是一家靠谱，有实力，聚焦客户并成就客户的公司。我们也曾经是留学生，经历过留学申请，漂洋过海" +
          "来到大洋彼岸求学，再到毕业之后求职，工作。一路走来，我们经历过因为申请学校定位而烦恼，也经历过因为" +
          "申请过程中的琐碎忙的焦头烂额，亦或者是陷入过求职过程中的一些误区，等等这些留学申请和求职的“坑”。" +
          "我们希望能帮助更多的留学生，避开那些“坑”，在你们追寻梦想的道路上拉你一把，助你们收获更好的自己和未来！" +
          "\n\n让佳一未来为你铺路，你只管往前冲！",
  advantageOverview: "专业踏实的个性化服务，贴心且有实力的导师资源，不论是留学申请，还是求职上岸，都是你的不二之选。",
  advantages: [
    {
      title: "专业踏实的理念",
      icon: <FaThumbsUp className="mr-3 mb-2" style={{color: 'orange', fontSize: '25px'}}/>,
      description: "相比于重营销，轻服务的一些留学或者求职企业，佳一未来更注重服务，" +
                   "深耕留学生申请和求职的各种资源，致力于打造最优质留学生申请和求职的服务。"
    },
    {
      title: "严格筛选的服务团队",
      icon: <IoIosPeople className="mr-3" style={{color: 'orange', fontSize: '35px'}}/>,
      description: "佳一未来90%的团队人员来自top30院校毕业生，亦或者是来自世界500强企业。" +
                   "只有优秀的导师团队才能更专业可靠地服务每一位学生。"
    },
    {
      title: "个性化服务",
      icon: <FaHandHoldingHeart className="mr-3 mb-2" style={{color: 'orange', fontSize: '28px'}}/>,
      description: "佳一未来会根据学生的背景和需求，量身定制规划和服务内容，确保所有的学生能收获贴心的服务和满满的干货。"
    },
  ],
  joinUs: "佳一未来致力于为学生提供最专业贴心的留学申请和求职服务。如果你也认同我们的理念和使命，"+
          "佳一未来欢迎并且希望为之尽一份力，每一位年轻优秀又充满活力的你加入！"
}
