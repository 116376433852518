export const headerItems = [
  {
    name: "首页",
    url: "/",
  },
  {
    name: "留学申请",
    url: "/school-application",
    // subItems: [
    //   {
    //     name: "本科申请",
    //     url: "/undergraduate-application",
    //   },
    //   {
    //     name: "硕士申请",
    //     url: "/master-application",
    //   },
    // ]
  },
  {
    name: "求职服务",
    subItems: [
      {
        name: "求职必修课",
        url: "/job-hunting"
      },
      {
        name: "模拟面试",
        url: "/mock-interview",
      },
    ]
  },
  {
    name: "关于我们",
    subItems: [
      {
        name: "公司介绍",
        url: "/about-us",
      },
      {
        name: "加入我们",
        url: "/join-us",
      }
    ]

  },
]
