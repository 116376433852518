import companyLogo from '../imgs/Company_Logo.png';
import wechat from '../imgs/Company_Wechat.png';

export const company = {
  name: "佳一未来",
  logo: companyLogo,
  email: "jiayifuture@outlook.com",
  hrEmail: "hr.jiayifuture@outlook.com",
  wechatImg: wechat,
  wechatId: "jiayifuture",
}
