import React from 'react';
import JobTemplate from '../JobTemplate';
import headerBackground from '../../../imgs/Job-header-background.jpg';

import { aboutInterview,
         questionsInterview,
         workflow,
         services
       } from '../../../business/JobHuntingPage';

function JobHunting() {

  const contents = {
    headerBackground: headerBackground,
    header: {
      title: "求职必修课",
      slogan: "告 别 迷 茫，不 走 弯 路"
    },
    about: {
      title: "关于求职的那些事儿",
      data: aboutInterview,
      questions: questionsInterview
    },
    program: {
      title: "项目简介",
      services: services
    },
    workflow: {
      title: "流程",
      steps: workflow
    }
  }

  return (
    <>
      <JobTemplate contents={contents} />
    </>
  )
}

export default JobHunting;
