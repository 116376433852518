import React from 'react';
import ApplicationTemplate from './ApplicationTemplate';
import { chosenReasons,
         applicationPrograms,
         programFlows,
         generalQuestions
       } from '../../business/MasterApplicationPage';

function MasterApplication() {

  return (
    <>
      <ApplicationTemplate
        name={"留学申请"}
        chosenReasons={chosenReasons}
        applicationPrograms={applicationPrograms}
        programFlows={programFlows}
        generalQuestions={generalQuestions}
      />
    </>
  )
}

export default MasterApplication;
