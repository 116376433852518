import offer1 from '../imgs/schoolOffers/Slide1.png';
import offer2 from '../imgs/schoolOffers/Slide2.png';
import offer3 from '../imgs/schoolOffers/Slide3.png';
import offer4 from '../imgs/schoolOffers/Slide4.png';
import offer5 from '../imgs/schoolOffers/Slide5.png';
import offer6 from '../imgs/schoolOffers/Slide6.png';
import offer7 from '../imgs/schoolOffers/Slide7.png';
import offer8 from '../imgs/schoolOffers/Slide8.png';
import offer9 from '../imgs/schoolOffers/Slide9.png';
import offer10 from '../imgs/schoolOffers/Slide10.png';
import offer11 from '../imgs/schoolOffers/Slide11.png';
import offer12 from '../imgs/schoolOffers/Slide12.png';
import offer13 from '../imgs/schoolOffers/Slide13.png';
import offer14 from '../imgs/schoolOffers/Slide14.png';
import offer15 from '../imgs/schoolOffers/Slide15.png';
import offer16 from '../imgs/schoolOffers/Slide16.png';
import offer17 from '../imgs/schoolOffers/Slide17.png';
import offer18 from '../imgs/schoolOffers/Slide18.png';

import { university } from './University';

export const applicationData = [
  {
    name: '申请成功率',
    value: "99%"
  },
  {
    name: 'Top30名校录取率',
    value: "93%"
  },
  {
    name: '学生平均获得offer',
    value: "4"
  },
]

export const schoolApplicationOffers = [
  {
    name: "L同学",
    school: university.msu.name,
    gpa: "3.9 / 4.0",
    offerSchool: university.brown.name,
    offerSchoolImgLogo: university.brown.logo,
    offerMajor: "Data Science Initiative, SCM program",
    offerImgSrc: offer1,
  },
  {
    name: "X同学",
    school: university.ohioState.name,
    gpa: "3.8 / 4.0",
    offerSchool: university.cmu.name,
    offerSchoolImgLogo: university.cmu.logo,
    offerMajor: "Electrical and Computer Engineering",
    offerImgSrc: offer11,
  },
  {
    name: "W同学",
    school: university.ohioState.name,
    gpa: "3.5 / 4.0",
    offerSchool: university.columbia.name,
    offerSchoolImgLogo: university.columbia.logo,
    offerMajor: "Master of Arts in Statistics",
    offerImgSrc: offer2,
  },
  {
    name: "C同学",
    school: university.northCarolineState.name,
    gpa: "3.8 / 4.0",
    offerSchool: university.vanderbilt.name,
    offerSchoolImgLogo: university.vanderbilt.logo,
    offerMajor: "Learning and Design program ",
    offerImgSrc: offer18,
  },
  {
    name: "L同学",
    school: university.msu.name,
    gpa: "3.9 / 4.0",
    offerSchool: university.cornell.name,
    offerSchoolImgLogo: university.cornell.logo,
    offerMajor: "Applied Statistics",
    offerImgSrc: offer5,
  },
  {
    name: "L同学",
    school: university.pennState.name,
    gpa: "3.6 / 4.0",
    offerSchool: university.uOfChicago.name,
    offerSchoolImgLogo: university.uOfChicago.logo,
    offerMajor: "Analytics Program",
    scholarship: "$2500",
    offerImgSrc: offer7,
  },
  {
    name: "X同学",
    school: university.ohioState.name,
    gpa: "3.8 / 4.0",
    offerSchool: university.gt.name,
    offerSchoolImgLogo: university.gt.logo,
    offerMajor: "Computer Science",
    offerImgSrc: offer12,
  },
  {
    name: "Z同学",
    school: university.asu.name,
    gpa: "3.3 / 4.0",
    offerSchool: university.columbia.name,
    offerSchoolImgLogo: university.columbia.logo,
    offerMajor: "Operations Research Master of Science",
    offerImgSrc: offer3,
  },
  {
    name: "H同学",
    school: university.pennState.name,
    gpa: "3.7 / 4.0",
    offerSchool: university.uOfToronto.name,
    offerSchoolImgLogo: university.uOfToronto.logo,
    offerMajor: "Computer Science",
    offerImgSrc: offer13,
  },
  {
    name: "C同学",
    school: university.usc.name,
    gpa: "3.7 / 4.0",
    offerSchool: university.duke.name,
    offerSchoolImgLogo: university.duke.logo,
    offerMajor: "Statistical Science",
    offerImgSrc: offer6,
  },
  {
    name: "C同学",
    school: university.msu.name,
    gpa: "3.7 / 4.0",
    offerSchool: university.uOfToronto.name,
    offerSchoolImgLogo: university.uOfToronto.logo,
    offerMajor: "Master of Education, Curriculum and Pedagogy",
    offerImgSrc: offer17,
  },
  {
    name: "L同学",
    school: university.msu.name,
    gpa: "3.9 / 4.0",
    offerSchool: university.uOfMichigan.name,
    offerSchoolImgLogo: university.uOfMichigan.logo,
    offerMajor: "Data Science program",
    offerImgSrc: offer8,
  },
  {
    name: "Y同学",
    school: university.ohioState.name,
    offerSchool: university.uOfBritishColumbia.name,
    offerSchoolImgLogo: university.uOfBritishColumbia.logo,
    offerMajor: "Master of Education",
    offerImgSrc: offer16,
  },
  {
    name: "Z同学",
    school: university.asu.name,
    gpa: "3.6 / 4.0",
    offerSchool: university.usc.name,
    offerSchoolImgLogo: university.usc.logo,
    offerMajor: "Applied Data Science",
    offerImgSrc: offer9,
  },
  {
    name: "M同学",
    school: university.pennState.name,
    gpa: "3.2 / 4.0",
    offerSchool: university.columbia.name,
    offerSchoolImgLogo: university.columbia.logo,
    offerMajor: "Master of Science in Applied Analytics",
    offerImgSrc: offer4,
  },
  {
    name: "H同学",
    school: university.ohioState.name,
    gpa: "3.7 / 4.0",
    offerSchool: university.uOfToronto.name,
    offerSchoolImgLogo: university.uOfToronto.logo,
    offerMajor: "Management Analytics program",
    offerImgSrc: offer10,
  },
  {
    name: "L同学",
    school: university.asu.name,
    gpa: "3.5 / 4.0",
    offerSchool: university.usc.name,
    offerSchoolImgLogo: university.usc.logo,
    offerMajor: "Computer Science",
    offerImgSrc: offer14,
  },
  {
    name: "C同学",
    school: university.msu.name,
    gpa: "3.8 / 4.0",
    offerSchool: university.uPenn.name,
    offerSchoolImgLogo: university.uPenn.logo,
    offerMajor: "Teaching, Learning, and Leadership ",
    scholarship: "$5000",
    offerImgSrc: offer15,
  },
]
