import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import Button from 'react-bootstrap/Button';

function CommonQuestions({ generalQuestions, contactToggle }) {
  return (
    <div className="jumbotron" style={{background: '#fff'}}>
      <div className="text-center">
        <h2>常见问题</h2>
      </div>

      <div className="container mt-3">
        <div className="row">
          {
            generalQuestions.map((q, index) => (
              <div className="col-md-6 mt-2" key={`question-${index}`}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <h6 className="mb-0">
                      <QuestionAnswerIcon className="mr-3" style={{color: 'orange'}}/>
                      {q.question}
                    </h6>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography style={{ whiteSpace: 'pre-wrap' }}>
                      {q.answer}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))
          }
        </div>
      </div>

      <div className="text-center mt-4">
        <Button
          variant="outline-warning"
          onClick={contactToggle}
          style={{borderRadius: '25px', padding: '5px 20px', fontWeight: 500, fontSize: '20px'}}
        >
          仍有问题? 立即咨询
        </Button>
      </div>
    </div>
  )
}

export default CommonQuestions
