import banner_elite from '../imgs/精英计划.png';
import banner_recruite from '../imgs/招募计划.png';

export const landingSliderImages = [
  {
    src: banner_elite,
    alt: "精英计划",
    link: "/school-application"
  },
  {
    src: banner_recruite,
    alt: "招聘计划",
    link: "/join-us"
  }
]

export const trustUsReasons = [
  "佳一未来是一家靠谱，有实力，聚焦客户并成就客户的公司。我们也曾经是留学生，经历过留学申请，漂洋过海" +
  "来到大洋彼岸求学，再到毕业之后求职，工作。一路走来，我们经历过因为申请学校定位而烦恼，也经历过因为申请过程" +
  "中的琐碎忙的焦头烂额，亦或者是陷入过求职过程中的一些误区，等等这些留学申请和求职的“坑”。我们希望能帮助" +
  "更多的留学生，避开那些“坑”，在你们追寻梦想的道路上拉你一把，助你们收获更好的自己和未来！\n\n" +
  "让佳一未来为你铺路，你只管往前冲！"
]

export const schoolApplicationChosenReasons = [
  {
    title: "精准定位",
    description: "佳一未来的留学申请顾问均来自Top30名校并拥有丰富的留学申请经历，" +
                  "深度了解留学申请流程，熟悉不同学校学院偏好，帮你精准定位，赢在起跑线。"
  },
  {
    title: "优质文书",
    description: "佳一未来会根据每个学生不同的特点和背景，打造专属文书。 " +
                  "所有文书都会经过 Native Speaker 修改润色，全程高标准严要求，只为能让招生官眼前一亮。"
  },
  {
    title: "申请系统",
    description: "佳一未来独家研发的留学申请系统，申请过程有条不紊，申请流程严格把控。学生可以随时登录，" +
                  "一键查询学校申请进度，全程清晰透明，学生省心放心。"
  },
  {
    title: "资源丰富",
    description: "佳一未来不仅解决留学申请中的琐事，还提供各种生活和求职的资源，" +
                  "帮助留学生能更好的适应当地的学习和生活，并为之后求职上岸做好准备。"
  }
]

export const jobChosenReasons = [
  {
    title: "金牌导师",
    description: "佳一未来导师团队均来自世界500强企业。只有优秀的导师团队才能更专业可靠的服务每一位学生。"
  },
  {
    title: "视频面对面",
    description: "相比于录好的视频，佳一未来采用导师和学生视频面对面的方式，实时高效地让学生全面参与。"
  },
  {
    title: "个性化服务",
    description: "导师会提前和学生沟通，了解学生的背景和需求，从而制定个性化内容，因材施教。"
  },
  {
    title: "满满干货",
    description: "根据学生的背景和需求制定内容，最简单直接的方式解答学生疑问，直击痛点，不拖泥带水。"
  }
]
