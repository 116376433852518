export const chosenReasons = [
  {
    name: "顶尖申请团队",
    description: "佳一未来的咨询顾问均来自Top30名校并拥有丰富的留学申请经历，全面严格地把控申请流程中的每一个细节。"
  },
  {
    name: "定制申请计划",
    description: "佳一未来会根据每个学生不同的需求，专属制定选校申请策略，筛选适合的学校和项目，避免纯排名的选校误区。"
  },
  {
    name: "优质文书",
    description: "佳一未来会根据每个学生不同的特点和背景，打造专属文书。所有文书都会经过 Native Speaker 修改润色。"
  },
  {
    name: "面试精品辅导",
    description: "佳一未来会和学生一起准备院校面试，根据学生背景和院校偏好，进行1v1模拟面试，反复练习面试细节，提高录取机率。"
  },
  {
    name: "一站式申请管理系统",
    description: "佳一未来独家研发的留学申请系统，学生可以随时登录，一键查询学校申请资料和申请进度，全程清晰透明。"
  },
  {
    name: "技能提升",
    description: "佳一未来不仅解决留学申请中的琐事，还提供各种生活和求职的资源，" +
                  "帮助留学生能更好的适应当地的学习和生活，并为之后求职上岸做好准备。"
  },
]

export const applicationPrograms = [
  {
    name:"文书服务",
    description: "包含文书素材采集，文书构思头脑风暴，文书创作，和简历精修",
    fitPeople: [
      "需要文书构思，头脑风暴，和Native Speaker对文书进行润色",
      "有时间和精力自己处理选校定位，申请账号创建，资料填写，资料递送等申请过程中的琐事",
    ],
  },
  {
    name:"申请服务",
    description: "包含申请账号创建，资料填写，资料递送，申请状态查询，和简历精修",
    fitPeople: [
      "需要有人帮忙处理学校网申琐事，并把控申请进程",
      "有能力和时间自己处理选校定位，文书构思，头脑风暴，并撰写文书",
    ],
  },
  {
    name:"一站式申请服务",
    description: "保姆级服务处理文书和申请过程中的琐事，包含选校定位，文书，网申等8大服务内容",
    fitPeople: [
      "需要有人帮忙处理文书和申请过程中的琐事",
      "对北美求职有一定的了解和规划",
    ],
  },
  {
    name:"精英计划",
    description: "保姆级服务学校申请服务，包含选校定位，文书，网申，求职培训，公司内推等12大服务项目",
    fitPeople: [
      "需要有人帮忙处理文书和申请过程中的琐事",
      "希望获得北美求职的各种资源，提高求职成功率"
    ],
  },
]

export const programFlows = [
  {
    stage: "前期规划",
    services: [
      {
        name: "制定专属申请策略",
        description: "了解学生背景和申请需求，根据背景和需求制定专属申请策略",
        programValues: [1, 1, 1, 1]
      },
      {
        name: "文书素材采集",
        description: "和学生深入沟通，挖掘闪光点，拟定文书方向和材料",
        programValues: [1, 0, 1, 1]
      },
      {
        name: "简历精修",
        description: "根据院校专业，优化完善简历和CV，突出个人特色",
        programValues: [1, 1, 1, 1]
      },
    ]
  },
  {
    stage: "申请阶段",
    services: [
      {
        name: "选校定位",
        description: "根据学生背景和需求，筛选匹配适合学生的学校",
        programValues: [1, 1, 1, 1]
      },
      {
        name: "精品文书创作",
        description: "根据拟定文书方向和材料开始文书创作，并由Native Speaker修改润色",
        programValues: [1, 0, 1, 1]
      },
      {
        name: "申请全程辅助",
        description: "学校申请账号创建，资料填写，申请材料递送，申请状态查询，保姆级服务",
        programValues: [0, 1, 1, 1]
      },
      {
        name: "院校面试指导",
        description: "和学生提前准备面试细节，进行1v1 mock interview，提高录取机率",
        programValues: [0, 0, 1, 1]
      },
    ]
  },
  {
    stage: "录取后期",
    services: [
      {
        name: "offer选校指导",
        description: "综合评估录取学校，根据学生背景给予合理选校建议",
        programValues: [0, 0, 1, 1]
      },
      {
        name: "求职简历修改",
        description: "\"FLAG\"公司的导师帮你修改简历，突出个人特色，全方位提升简历通过率！",
        programValues: [0,0,0,1]
      },
      {
        name: "求职培训",
        description: "\"FLAG\"公司的导师为你讲解求职的各种小技巧，全面提升你找实习的成功率！",
        programValues: [0,0,0,1]
      },
      {
        name: "模拟面试",
        description: "\"FLAG\"公司的导师和你进行1:1 mock interview，全面提升面试技能，增加面试通过率！",
        programValues: [0,0,0,1]
      },
      {
        name: "求职内推",
        description: "给你内推各大公司的职位，增加获得面试的可能性！",
        programValues: [0,0,0,1]
      }
    ]
  }
]

export const generalQuestions = [
  {
    question: "佳一未来和其他留学公司有什么区别？",
    answer: "1. 佳一未来的每个学生都会有一位专属老师，专门负责对接和处理申请过程中的所有事务，而非像传统中介，有一个申请老师，" +
                "一个文书老师，还有一个咨询老师，学生有问题不知道咨询哪个老师。 \n" +
"2. 佳一未来会根据每个学生的背景和需求，专属定制申请策略，保证每位学生的所有材料的个性定制，而非像传统中介，批量生产学生 \n" +
"3. 佳一未来不与任何学校签订互惠协议，保证申请的每所学校都尽量匹配学生的背景和需求，" +
"而非像传统中介，一边收学校回扣，一边收学生费用，并忽略学生的背景和需求，极力引导学生申请该校；"
  },
  {
    question: "GRE要考到多少才能申请名校？有不需要GRE成绩的学校么？",
    answer: "一般而言，GRE320以上算是不错的分数。但是每个学校每个专业对于GRE的要求也不完全一样，" +
"有的学校专业需要总成绩达到一定的分数，也有的学校专业需要其中的单项(Verbal，Quantitative，和Analytical Writing)成绩达到一定的分数。\n\n " +
"美国研究生的录取看中的是学生的综合能力。GPA、GRE、TOEFL（部分学生选考）、相关实习经历、相关项目背景等。" +
"并不是用单纯的GRE来衡量是否可以申请名校。当然，在其他背景条件类似的情况下，GRE成绩也是越高越好，" +
"但也有些美国研究生项目是不需要提供GRE成绩单。即使学校不做要求，一般老师还是建议学生有GRE成绩更好。"
  },
  {
    question: "美国研究生学院录取学生主要参考什么因素?",
    answer: "美国研究生学院录取学生主要参考成绩和个人背景因素。成绩方面，主要包括托福成绩 " +
"（如果是美本或在美国大学有2年fulltime student的经历，有很多项目能免去托福成绩）、GRE/GMAT成绩和GPA成绩。" +
"背景方面的因素，根据学生申请的专业不同，学校录取学生时看中的因素也不同。比如申请金融等商科专业的学生，" +
"学生参加活动的经历、实习经历、工作经历都是很好的背景因素。申请化学这样的理科专业，做实验、参加课题研究、" +
"发论文这样的经历是很不错的。另外每个学校看中的因素也不同，所以在申请的时候要具体分析。"
  },
  {
    question: "美国研究生申请时间是什么时候？啥时候发offer呢？",
    answer: "美国研究生申请一般提前将近一年进行。如果学生希望大四毕业直接去美国读研究生，" +
"申请的时间应该在大三结束，刚上大四的时候。多数学校网申系统会在8-9月份开放，9月到12月是申请最佳时期，" +
"每个大学都有申请截止日期，截止日期的意思就是必须在那个日子前完成申请，过期就不接受了。一般早申请会比较好，" +
"所以如果成绩和条件都具备了，要尽早递交申请。\n\n"  +
"对于发放offer的时间，美国大学一般在2到6月份发放录取结果的居多，其中3、4月份是发放录取的高峰期。" +
"根据每个大学的进度和学生申请时间的早晚不同，发放录取结果的时间并不统一。"
  },
  {
    question: "GPA要有多少分才能申请到好学校? ",
    answer: "一般情况下，GPA 3.5以上算是不错的分数，如果希望申请到不错的学校，" +
"最低要求要有3.0以上，否则选择学校的时候会受到一些局限。" +
"绝大多数美国学校还是很看重学生的GPA成绩的。所以有出国打算的学生一定要注意自己的大学成绩，" +
"避免因为成绩不理想而影响研究生的申请。\n\n" +
"当然，以上只是宽泛的标准，根据学生其它各种条件的不同，并不能一概而论。" +
"以前我们曾经有GPA 3.1的学生就申请到了计算机神校，卡耐基梅隆大学。"
  },
  {
    question: "如果GPA成绩太低怎么办？",
    answer: "对于GPA成绩偏低的学生，如果正处于大一或者大二，还有充足的时间，一定要在后续的考试中争取获得高分，把GPA拉上去。" +
"另外一些国内大学允许学生重修课程，学生可以通过重修低分课程取得更高的成绩把之前的成绩覆盖。" +
"如果大局已定，没有任何方式可以弥补，那么学生只能尽量提高自己的背景因素以提升申请竞争优势。"
  },
]
