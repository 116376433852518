import React from 'react';
import Button from 'react-bootstrap/Button';
import PageTitleTemplate from '../../utils/PageTitleTemplate';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import headerBackground from '../../../imgs/AboutUs_header_background.jpg';
import aboutUsImg from '../../../imgs/AboutUs_aboutUs.png';
import joinUsImg from '../../../imgs/AboutUs_joinUs.png';
import { makeStyles } from '@material-ui/core/styles';
import { company } from '../../../business/CompanyInfo';
import { aboutUs } from '../../../business/AboutUs';

const useStyles = makeStyles(theme => ({
  logoTitle: {
    width: '20%',
    height: 'auto'
  },
  quoteIcon: {
    color: '#ffd280',
    fontSize: '35px'
  },
  text: {
    whiteSpace: 'pre-wrap'
  }
}));

function AboutUs() {
  const styles = useStyles();

  return (
    <>
      <PageTitleTemplate
        name={company.name}
        slogan="努 力 佳 一，未 来 可 期"
        background={headerBackground}
      />
      <div className="jumbotron mb-0" style={{background: 'white'}}>
        <div className="container">
          <h2 className="text-center">关于我们</h2>
          <div className="row px-lg-5 mt-3">
            <div className="col-md-8">
              <p className={`${styles.text} mt-3`}>{aboutUs.vision}</p>
            </div>

            <div className="col-md-4 mt-3">
              <img src={aboutUsImg} alt="公司愿景"/>
            </div>
          </div>
        </div>
      </div>

      <div className="jumbotron mb-0">
        <div className="container">
          <h2 className="text-center">我们的优势</h2>
          <div className="mx-auto w-lg-75">
            <FormatQuoteIcon className={styles.quoteIcon} style={{transform: 'rotate(180deg)'}}/>
            <p className="mx-3 mx-md-5 my-0 text-lg-center" style={{lineHeight: 2, fontSize: '18px'}}>{aboutUs.advantageOverview}</p>
            <FormatQuoteIcon className={`justify-content-end ${styles.quoteIcon}`} style={{float: 'right'}}/>
          </div>
          <div className="row px-lg-5 mt-5">
            {
              aboutUs.advantages.map((advantage) => (
                <div className="col-lg-4 px-4" key={advantage.title}>
                  <h5>{advantage.icon} {advantage.title}</h5>
                  <p className="mt-auto">{advantage.description}</p>
                </div>
              ))
            }
          </div>
        </div>
      </div>

      <div className="jumbotron mb-0" style={{background: 'white'}}>
        <div className="container">
          <h2 className="text-center">加入我们</h2>
          <div className="row px-lg-5">
            <div className="col-md-5 mt-3">
              <img src={joinUsImg} alt="加入我们"/>
            </div>
            <div className="col-md-7 mt-3 pl-lg-5 my-md-auto">
              <p> {aboutUs.joinUs} </p>
              <div className="text-center text-md-left">
                <Button
                  className="mt-4"
                  variant="warning"
                  href="/join-us"
                  style={{borderRadius: '25px',
                          padding: '5px 40px',
                          background: 'orange',
                          fontWeight: 500,
                          fontSize: '18px'
                        }}
                >
                  查看职位
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutUs;
