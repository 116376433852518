export const aboutInterview = [
  {
    description: "390万美国应届毕业生",
    data: [
      {
        value: 10,
        color: "#FFBB28"
      },
      {
        value: 50,
        color: "#808080"
      }
    ],
    unit: "秒",
  },
  {
    description: "16％留学生在美找到工作",
    data: [
      {
        value: 10,
        color: "#FFBB28"
      },
      {
        value: 50,
        color: "#808080"
      }
    ],
    unit: "%",
  },
  {
    description: "简历到不了HR手里就已被机器自动淘汰",
    data: [
      {
        value: 95,
        color: "#FFBB28"
      },
      {
        value: 5,
        color: "#808080"
      }
    ],
    unit: "%",
  }
]

export const questionsInterview = [
  "想找实习或工作，但感到迷茫，无从下手？",
  "不知道如何规划求职流程和准备工作？",
  "简历总是石沉大海，不知道如何增加简历的通过率？",
  "不知道如何准备你的elevator pitch？",
  "在career fair上不知道如何network？"
]

export const services = [
  {
    name: "求职必修课",
    summary: "从求职流程和规划，到简历，elevator pitch，career fair上的networking，一门课让你告别迷茫，不走弯路！",
    description: "留学生在找工作和实习的时候看似困难重重，工作量巨大，其实很多人都放了一些很常见但又致命的问题，" +
                 "并且不知道有一些小技巧能够让你的求职事半功倍。这门必修课包含了留学生找工作和实习的方方面面，" +
                 "是我们多位成功进入FLAG的小伙伴们一起总结，精炼求职过程中的心得，" +
                 "最终融合而成。如果你现在准备开始找实习或者全职工作，相信这门课的材料能让你理清思路，" +
                 "避开求职路上的那些“坑”，收获一份满意的offer！",
    gain: [
      "求职流程以及如何做好求职的准备工作，学会合理规划",
      "写简历的小技巧，突出自己的闪光点，简历通过率提高80%",
      "如何准备elevator pitch，增加求职的竞争力",
      "career fair上networking全解析，快速拿下工作面试"
    ]
  }
]

export const workflow = [
  {
    name: "微信咨询",
    detail: "解答困惑，并了解学生背景和需求，有针对性地制定个性化课程内容"
  },
  {
    name: "预约导师",
    detail: "和导师沟通交流，预约时间进行模拟面试"
  },
  {
    name: "1v1培训",
    detail: "根据您的个性化培训方案，完成课程培训"
  },
  {
    name: "简历修改",
    detail: "导师会根据你的背景和经历进行简历修改，提高简历相关性和个人亮点"
  }
]

export const generalQuestions = [
  {
    question: "test job hunting",
    answer: "This is the answer"
  },
  {
    question: "test",
    answer: "This is the answer"
  },
  {
    question: "test",
    answer: "This is the answer"
  },
  {
    question: "test",
    answer: "This is the answer"
  }
]
