import React from 'react';

function ResumeWorkshop() {
  return (
    <>
resumeWorkshop
    </>
  )
}

export default ResumeWorkshop;
