export const jobs = [
  {
    name: "软件开发工程师 (实习)",
    location: "远程",
    type: "兼职",
    date: "July 01, 2023",
    responsibilities: [
      "主导项目的业务需求设计、架构设计、系统设计，代码编写，系统测试",
      "负责项目软件的核心模块开发及维护",
      "统筹推进项目总体进程，持续发现系统中存在的功能、性能、架构等问题并提出改进方案"
    ],
    requirements: [
      "有很强的学习能力和进取心，性格开朗，具备合作精神，责任心强，有较强的沟通能力。",
      "逻辑分析能力、学习能力和创新能力强，具有团队合作精神，良好的语言沟通表达能力。",
      "以下任何一项技能都是优势：有网页开发经历，用过任何一个或多个公有云平台 (AWS, GCP, etc)。" 
    ]
  },
  {
    name: "新媒体市场运营（实习）",
    location: "远程",
    type: "兼职",
    date: "July 01, 2023",
    responsibilities: [
      "负责公司企业文化的对外宣传和市场推广；官方网站、网页、官方微博、微信等平台的日常内容的策划和运营",
      "负责平台内容策划撰写，图文美化排版编辑、内容发布推广等相关工作",
      "收集、研究网络热点话题，结合新媒体特性，对微博、微信内容的进行实时更新；"
    ],
    requirements: [
      "具备优秀的洞察力和策略思维，对待工作有⾼度的责任⼼和执⾏⼒",
      "拥有整合营销能⼒和基本的商务谈判能⼒",
      "有进取心，性格开朗，具备合作精神，责任心强，有较强的沟通能力"
    ]
  },
]
