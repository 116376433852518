import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { company } from '../../business/CompanyInfo';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ContactDialog(props) {

  const handleClose = () => {
    props.openToggle();
  }

  return (
    <React.Fragment>
    <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        maxWidth="sm"
      >
      <DialogTitle id="alert-dialog-title">
        <strong>微信咨询或报名</strong>
        <IconButton
          style={{right: '12px', top: '8px', position: 'absolute'}}
          onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <div style={{textAlign: "center"}}>
          {/*<p style={{fontSize: '22px'}}> 扫码<strong>免费</strong>获得GRE全套资料 &darr; </p>*/}
          <img
            src={company.wechatImg}
            alt={`wechat ID: ${company.wechatId}`}
            style={{
              margin: 'auto',
              display: 'block',
              maxWidth: '70%',
              height: 'auto',
            }}
          />
        </div>
      </DialogContent>

    </Dialog>
    </React.Fragment>
  )
}

export default ContactDialog;
