import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import JobDialog from './JobDialog';
import PageTitleTemplate from '../../utils/PageTitleTemplate';
import headerBackground from '../../../imgs/AboutUs_header_background.jpg';
import './JoinUsStyles.scss';
import { makeStyles } from '@material-ui/core/styles';
import { jobs } from '../../../business/JoinUsPage';
import { company } from '../../../business/CompanyInfo';

const useStyles = makeStyles(theme => ({
  card: {
    width: '75%',
    margin: '0 auto'
  },
  button: {
    background: 'orange',
    color: 'white',
    padding: '5px 20px',
    fontWeight: 500,
    '&:hover': {
      background: 'orange'
    }
  }
}));

function JoinUs() {
  const styles = useStyles();
  const date_year = new Date().getFullYear();
  const [position, setPosition] = useState({})
  const [jobOpen, setJobOpen] = useState(false);

  const toggleJobOpen = (selectedJob) => {
    setJobOpen(!jobOpen);
    if (Object.keys(position).length > 0) {
      setPosition({});
    } else {
      setPosition(selectedJob);
    }
  }

  return (
    <>
      <PageTitleTemplate
        name={`${date_year} ${company.name} 招 募`}
        slogan="一 起 做 一 些 有 意 思 的 事！"
        background={headerBackground}
      />

      <div className="container mt-4 w-lg-75 px-4 mx-lg-5">
      <h4 className="py-3">还在等什么？{company.name}期待你的加入！</h4>
      {
        jobs.map((job) => (
          <div className={`card my-3`} key={job.name}>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <h5 className="card-title pt-md-2" style={{color: 'orange'}}>{job.name}</h5>
                  <p className="card-text">{job.date} &nbsp; {job.type}  &nbsp; {job.location}</p>
                </div>
                <div className="col-md-6">
                  <div className="row text-right">
                    <div className="col mt-1">
                      <Button
                        variant="warning"
                        className={styles.button}
                        href={`mailto:${company.hrEmail}`}
                      >
                        立即申请
                      </Button>
                    </div>
                  </div>
                  <div className="row mt-1">
                    <div className="col text-right">
                    <Button
                      className={styles.button}
                      variant="warning"
                      onClick={()=>toggleJobOpen(job)}
                    >
                      了解更多
                    </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      }
      </div>
      <JobDialog
        open={jobOpen}
        openToggle={toggleJobOpen}
        job={position}
      />
    </>
  )
}

export default JoinUs;
