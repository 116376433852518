export const policies = [
  {
    name: "隐私政策",
    link: "/privacy-terms",
    updated: "July 24, 2021",
    description: [
      "test tseaffdsagdsgsdaf test tseaffdsagdsgsdaf test tseaffdsagdsgsdaf test tseaffdsagdsgsdaf",
      "test tseaffdsagdsgsdaf"
    ],
    contents: [
      {
        title: "Our Commitment to Privacy",
        description: ""
      },
      {
        title: "Our Commitment to Privacy",
        description: ""
      },
      {
        title: "Our Commitment to Privacy",
        description: ""
      },
      {
        title: "Contacting Us",
        description: "If you have any questions about our Privacy Policy, the practices of this site or your dealings with Weetok, please contact us at"
      },
    ]
  }
]
