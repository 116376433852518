import asu from '../imgs/universityLogos/asu.png';
import brown from '../imgs/universityLogos/brown.png';
import cmu from '../imgs/universityLogos/cmu.png';
import columbia from '../imgs/universityLogos/columbia.png';
import cornell from '../imgs/universityLogos/cornell.png';
import duke from '../imgs/universityLogos/duke.png';
import georgia_tech from '../imgs/universityLogos/georgia-tech.png';
import msu from '../imgs/universityLogos/michiganState.png';
import ohioState from '../imgs/universityLogos/ohioState.png';
import uOfBritishColumbia from '../imgs/universityLogos/uOfBritishColumbia.png';
import uOfChicago from '../imgs/universityLogos/uOfChicago.png';
import uOfMichigan from '../imgs/universityLogos/uOfMichigan.png';
import uOfToronto from '../imgs/universityLogos/uOfToronto.png';
import uPenn from '../imgs/universityLogos/uPenn.png';
import usc from '../imgs/universityLogos/usc.png';
import vanderbilt from '../imgs/universityLogos/VanderbiltUniversity.png';

export const university = {
  asu: {
    name: "Arizona State University",
    logo: asu
  },
  brown: {
    name: "Brown University",
    logo: brown
  },
  cmu: {
    name: "Carnegie Mellon University",
    logo: cmu
  },
  columbia: {
    name: "Columbia University",
    logo: columbia
  },
  cornell: {
    name: "Cornell University",
    logo: cornell
  },
  duke: {
    name: "Duke University",
    logo: duke
  },
  gt: {
    name: "Georgia Tech",
    logo: georgia_tech
  },
  msu: {
    name: "Michigan State University",
    logo: msu
  },
  northCarolineState: {
    name: "North Caroline State University"
  },
  ohioState: {
    name: "Ohio State University",
    logo: ohioState
  },
  pennState: {
    name: "Pennsylvania State University"
  },
  uOfBritishColumbia: {
    name: "University of British Columbia",
    logo: uOfBritishColumbia
  },
  uOfChicago: {
    name: "University of Chicago",
    logo: uOfChicago
  },
  uOfMichigan: {
    name: "University of Michigan",
    logo: uOfMichigan
  },
  uOfToronto: {
    name: "University of Toronto",
    logo: uOfToronto
  },
  uPenn: {
    name: "University of Pennsylvania",
    logo: uPenn
  },
  usc: {
    name: "University of Southern California",
    logo: usc
  },
  vanderbilt: {
    name: "Vanderbilt University",
    logo: vanderbilt
  }
}
